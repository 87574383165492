import React, { useState, useRef, useEffect, useContext } from "react";
import { FaAngleDown, FaEyeSlash } from "react-icons/fa";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../shared/Pagination";
import "../styles/servicestable.css";
import { formatDate } from "../shared/FormatDate";
import Select from "react-select";
import Checkbox from "../shared/Checkbox";
import DetailsOverlay from "../shared/DetailsOverlay";
import { MdDelete, MdEdit } from "react-icons/md";
import axios from "axios";
import { FaExchangeAlt } from "react-icons/fa";
import { IoCopy, IoDuplicate } from "react-icons/io5";
import { FaEye } from "react-icons/fa6";
import EditService from "../components/EditService";
import getNextId from "../shared/idIncrement";
import parse from "html-react-parser";
import Switch from "../shared/Switch";
import ConfirmAction from "../shared/ConfirmAction";
import { AppContext } from "../../context/AppContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdOutlineAdsClick } from "react-icons/md";
import { getCurrencySymbol } from "../../client/shared/CurrencySymbol";
import { convertCurrency } from "../../client/shared/ConvertCurrency";

function ServicesTable({
  services,
  fetchServices,
  setFetchServices,
  tableRef,
  actionsRef,
  containerRef,
}) {
  const sortOptionValue = localStorage.getItem("sortOptionValue");
  const sortOptionLabel = localStorage.getItem("sortOptionLabel");
  const mainOption = {
    value: sortOptionValue || "sort",
    label: sortOptionLabel || "Sort...",
  };
  const [selectedOption, setSelectedOption] = useState(mainOption); // Set the default option
  const [idCounter, setIdCounter] = useState(1);
  const {
    panelId,
    setNotifyType,
    setNotifyMessage,
    siteUrl,
    setNotifyVisibility,
    setNotifyDuration,
    selectedAdminRate,
    ratesData,
    currentAdmin,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const { status } = useParams();
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedEditingService, setSelectedEditingService] = useState(null);
  const [categoryOption, setCategoryOption] = useState(null); // Set the default option
  const [changeCategory, setChangeCategory] = useState(false);
  const [changeAllCategory, setChangeAllCategory] = useState(false);
  const [selectedServiceAction, setselectedServiceAction] = useState("");
  const [detailsHead, setDetailsHead] = useState("");
  const [detaildBody, setDetailsBody] = useState("");
  const location = useLocation();
  const [showDetailsOverlay, setShowDetailsOverlay] = useState(false);
  const [showAllActions, setShowAllActions] = useState(false);
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [openedServiceId, setOpenedServiceId] = useState(null);
  const [serviceStatus, setServiceStatus] = useState(null);
  const [updatingAction, setUpdatingAction] = useState(false);
  const [actionText, setActionText] = useState("");
  const [headText, setHeadText] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [servTableWidth, setServTableWidth] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 1000;
  const headerRef = useRef();
  const menuRef = useRef();
  const allActionsBtnRef = useRef();
  const allActionMenuRef = useRef();
  const sortOptions = [
    { value: "category", label: "Sort By Category" },
    { value: "default", label: "Sort By Default" },
    { value: "name", label: "Sort Alphabetically" },
    { value: "id", label: "Sort By ID" },
    { value: "date", label: "Sort With Date" },
    // Add more options as needed
  ];
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index as needed
    control: (styles) => ({
      ...styles,
      borderRadius: "10px", // Add border-radius to the control (main container)
      borderColor: "var(--adbasebgcolor)",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--adbaseactcolor)"
          : "var(--adbasebgcolor)",
        color: "white",
      };
    },
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const handleStatusUpdate = async (newValue) => {
    setServiceStatus(newValue);
    await axios.post(`${siteUrl}/crud/update/doc`, {
      collection: "services",
      panelId: panelId,
      uid: selectedService?.uid,
      data: { status: newValue ? "active" : "disabled" },
    });
    Notify("success", "Updated Successfully");
  };

  const toggleStatus = async () => {
    // Determine the new status based on the current status
    const newStatus = !serviceStatus;

    // Update the local state immediately
    setServiceStatus(newStatus);

    // Update the database with the new status
    await handleStatusUpdate(newStatus);
  };

  useEffect(() => {
    let handler = (e) => {
      if (
        !menuRef.current?.contains(e.target) &&
        !headerRef.current?.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let handler = (e) => {
      if (
        !allActionMenuRef.current?.contains(e.target) &&
        !allActionsBtnRef.current?.contains(e.target)
      ) {
        setShowAllActions(false);
      }
    };
    if (showAllActions) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const showServicesActions = (service) => {
    setServiceStatus(service.status === "active");
    IdIncrement();
    setSelectedService(service);
    setOpenedServiceId(service.uid === openedServiceId ? null : service.uid);
    toggleOpen();
  };
  const handleServiceSelect = (serviceID) => {
    const isSelected = selectedServices.includes(serviceID);
    if (isSelected) {
      setSelectedServices((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== serviceID)
      );
    } else {
      setSelectedServices((prevSelected) => [...prevSelected, serviceID]);
    }
  };
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Save the selected sort option to localStorage
    localStorage.setItem("sortOptionValue", selectedOption.value);
    localStorage.setItem("sortOptionLabel", selectedOption.label);
  };

  const handleCategoryChange = async (selectedOption) => {
    setCategoryOption(selectedOption);
    await axios.post(`${siteUrl}/crud/update/doc`, {
      collection: "services",
      panelId: panelId,
      uid: selectedService.uid,
      data: {
        category: selectedOption.label,
      },
    });
    setFetchServices(!fetchServices);
    Notify("success", "Updated Successfully");
    setCategoryOption(null);
    setChangeCategory(false);
  };

  const copyIDs = async () => {
    function convertArrayToString(arr) {
      return arr.join(",");
    }
    let numbersString = convertArrayToString(selectedServices);
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(numbersString);
        Notify("success", "Successfully copied IDs");
      } else {
        Notify("error", "Failed to copy IDs");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  const IdIncrement = async () => {
    try {
      const newID = await getNextId("services", panelId, siteUrl);
      setIdCounter(newID);
    } catch (error) {
      setIdCounter(1);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        Notify("success", "Successfully copied ID");
      } else {
        Notify("error", "Failed to copy ID");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  const fetchCategories = async () => {
    const response = await axios.post(`${siteUrl}/crud/get/docs`, {
      collection: "categories",
      panelId: panelId,
    });
    const categoriesData = response.data.map((doc) => ({
      value: doc.name,
      label: doc.name,
    }));
    setCategories(categoriesData);
  };

  const handleSelectAll = () => {
    const allServicesIDs = mainServices.map((order) => order.id);
    setSelectedServices((prevSelected) =>
      prevSelected.length === mainServices.length ? [] : allServicesIDs
    );
  };
  const sortingFunctions = {
    default: (a, b) => a.position - b.position,
    date: (a, b) => b.timestamp - a.timestamp,
    name: (a, b) => a.name.localeCompare(b.name),
    id: (a, b) => a.id - b.id,
    category: (a, b) => b.category.localeCompare(a.category),
  };
  const sortedServices = [...services].sort(
    sortingFunctions[selectedOption?.value]
  );

  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const mainServices = sortedServices.slice(startIndex, endIndex);
  const pageCount = Math.ceil(sortedServices.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/control-panel/services/${status}?page=${newPage}`);
  };
  useEffect(() => {
    // Extract page number from the URL and set it as the initial state
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1); // Adjust for 0-based index
  }, []);

  const showServiceName = (body) => {
    setShowDetailsOverlay(true);
    setDetailsHead("Service Name");
    setDetailsBody(body);
  };

  const showServiceCategory = (body) => {
    setShowDetailsOverlay(true);
    setDetailsHead("Service Category");
    setDetailsBody(body);
  };

  const showServiceDescription = (body) => {
    setShowDetailsOverlay(true);
    setDetailsHead("Service Description");
    setDetailsBody(body);
  };

  const showEditService = (service) => {
    setSelectedEditingService(service);
  };

  const showChangeCategory = () => {
    fetchCategories();
    setChangeCategory(true);
  };

  const searchParams = new URLSearchParams(location.search);
  const SearchValue = searchParams.get("search");

  const hideActionOverlay = (event) => {
    if (event.target.classList.contains("adservicesactionoverlaycon")) {
      setChangeCategory(false);
      setChangeAllCategory(false);
      setCategoryOption(null);
    }
  };

  const duplicateService = async () => {
    await IdIncrement();
    try {
      setIsOpen(false);
      let modifiedData = { ...selectedService };
      delete modifiedData.id;
      delete modifiedData.uid;
      modifiedData.id = idCounter;
      modifiedData.timestamp = new Date();
      await axios.post(`${siteUrl}/crud/add/doc`, {
        collection: "services",
        panelId: panelId,
        data: modifiedData,
      });
      setFetchServices(!fetchServices);
      Notify("success", "Duplicated Successfully");
    } catch (error) {
      Notify("error", "Error duplicating service");
    }
  };

  const deleteService = async () => {
    try {
      setIsOpen(false);
      await axios.post(`${siteUrl}/crud/delete/doc`, {
        collection: "services",
        panelId: panelId,
        uid: selectedService.uid,
      });
      setFetchServices(!fetchServices);
      Notify("success", "Deleted Successfully");
    } catch (error) {
      Notify("error", "Error deleting service");
    }
  };

  const showAllActionsFunc = () => {
    setShowAllActions(!showAllActions);
  };

  const disableServicesConfirm = () => {
    setShowConfirmAction(true);
    setselectedServiceAction("disableAll");
    setButtonName("Disable All");
    setHeadText("Disable");
    setActionText("disable the selected service ");
  };

  const activateServicesConfirm = () => {
    setShowConfirmAction(true);
    setselectedServiceAction("activateAll");
    setButtonName("Activate All");
    setHeadText("Activate");
    setActionText("activate the selected service ");
  };

  const deleteServicesConfirm = () => {
    setShowConfirmAction(true);
    setselectedServiceAction("deleteAll");
    setButtonName("Delete All");
    setHeadText("Delete");
    setActionText("delete the selected service ");
  };

  const hideConfirmAction = (event) => {
    if (event.target.classList.contains("caoverlay")) {
      setShowConfirmAction(false);
    }
  };

  const cancelConfirmAction = () => {
    setShowConfirmAction(false);
  };

  const handleSelectedServices = () => {
    if (selectedServiceAction === "disableAll") {
      setUpdatingAction(true);
      const handleDisableServices = async () => {
        try {
          for (const serviceID of selectedServices) {
            const serviceDocs = await axios.post(`${siteUrl}/crud/get/docs`, {
              collection: "services",
              panelId: panelId,
            });
            const serviceData = serviceDocs.data.find(
              (serv) => serv.id === serviceID
            );
            await axios.post(`${siteUrl}/crud/update/doc`, {
              collection: "services",
              panelId: panelId,
              uid: serviceData.uid,
              data: { status: "disabled" },
            });
          }
          setUpdatingAction(false);
          setSelectedServices([]);
          setShowConfirmAction(false);
          setFetchServices(!fetchServices);
          Notify("success", "Updated Successfully");
        } catch (error) {
          setUpdatingAction(false);
          Notify("error", "Error updating services");
        }
      };
      handleDisableServices();
    }
    if (selectedServiceAction === "activateAll") {
      setUpdatingAction(true);
      const handleActivateServices = async () => {
        try {
          for (const serviceID of selectedServices) {
            const serviceDocs = await axios.post(`${siteUrl}/crud/get/docs`, {
              collection: "services",
              panelId: panelId,
            });
            const serviceData = serviceDocs.data.find(
              (serv) => serv.id === serviceID
            );
            await axios.post(`${siteUrl}/crud/update/doc`, {
              collection: "services",
              panelId: panelId,
              uid: serviceData.uid,
              data: { status: "active" },
            });
          }
          setUpdatingAction(false);
          setSelectedServices([]);
          setShowConfirmAction(false);
          setFetchServices(!fetchServices);
          Notify("success", "Updated Successfully");
        } catch (error) {
          setUpdatingAction(false);
          Notify("error", "Error updating services");
        }
      };
      handleActivateServices();
    }
    if (selectedServiceAction === "deleteAll") {
      setUpdatingAction(true);
      const handleDeleteServices = async () => {
        try {
          for (const serviceID of selectedServices) {
            const serviceDocs = await axios.post(`${siteUrl}/crud/get/docs`, {
              collection: "services",
              panelId: panelId,
            });
            const serviceData = serviceDocs.data.find(
              (serv) => serv.id === serviceID
            );
            await axios.post(`${siteUrl}/crud/delete/doc`, {
              collection: "services",
              panelId: panelId,
              uid: serviceData.uid,
            });
          }
          setUpdatingAction(false);
          setFetchServices(!fetchServices);
          setShowConfirmAction(false);
          setSelectedServices([]);
          Notify("success", "Deleted Successfully");
        } catch (error) {
          setUpdatingAction(false);
          Notify("error", "Error updating services");
        }
      };
      handleDeleteServices();
    }
  };

  const handleChangeCategory = () => {
    fetchCategories();
    setChangeAllCategory(true);
  };

  const handleAllCategoryChange = async (selectedOption) => {
    setCategoryOption(selectedOption);
    try {
      for (const serviceID of selectedServices) {
        const serviceDocs = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "services",
          panelId: panelId,
        });
        const serviceData = serviceDocs.data.find(
          (serv) => serv.id === serviceID
        );
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "services",
          panelId: panelId,
          uid: serviceData.uid,
          data: { category: selectedOption.label },
        });
      }
      setFetchServices(!fetchServices);
      Notify("success", "Updated Successfully");
      setSelectedServices([]);
      setChangeAllCategory(false);
    } catch (error) {
      Notify("error", "Error updating services");
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    Notify("warn", "Rearranging services... Wait!");
    const reorderedServices = Array.from(sortedServices);
    const [removed] = reorderedServices.splice(result.source.index, 1);
    reorderedServices.splice(result.destination.index, 0, removed);

    try {
      const data = reorderedServices.map((service, index) => ({
        uid: service.uid,
        position: index,
      }));

      // Function to send data in batches
      const sendInBatches = async (data, batchSize) => {
        for (let i = 0; i < data.length; i += batchSize) {
          const batch = data.slice(i, i + batchSize);
          await axios.post(`${siteUrl}/service/update/position`, {
            panelId: panelId,
            key: currentAdmin.apiKey,
            data: batch,
          });
        }
      };

      // Send data in batches of 500
      await sendInBatches(data, 500);
      setFetchServices(!fetchServices);
      Notify("success", "Rearranged successfully.");
    } catch (error) {
      console.error("Error updating positions:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const servicesTableCon = document.querySelector(".adservicestable");
      if (servicesTableCon) {
        setServTableWidth(servicesTableCon.offsetWidth);
      }
    };

    // Initial calculation on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="adservicescon">
      <div className="adservicessort" ref={containerRef}>
        <Select
          className="sortOptions"
          options={sortOptions}
          value={selectedOption}
          isSearchable={false}
          onChange={handleChange}
          styles={selectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              primary: "var(--adbasebgcolor)",
            },
          })}
        />
      </div>
      <div className="adservicestable" ref={tableRef}>
        <table>
          <thead>
            {selectedServices.length === 0 ? (
              <tr>
                <th className="adserviceshead adservmax20">
                  <Checkbox
                    onChange={() => handleSelectAll()}
                    checked={selectedServices.length === mainServices.length}
                  />
                </th>
                <th className="adserviceshead">ID</th>
                <th className="adserviceshead">
                  {servTableWidth <= 1330 ? "Category & Service" : "Category"}
                </th>
                <th
                  className={
                    servTableWidth >= 1330 ? "adserviceshead" : "d-none"
                  }
                >
                  Service
                </th>
                <th
                  className={
                    servTableWidth >= 1900 ? "adserviceshead" : "d-none"
                  }
                >
                  Provider
                </th>
                <th
                  className={
                    servTableWidth >= 1900 ? "adserviceshead" : "d-none"
                  }
                >
                  Min
                </th>
                <th
                  className={
                    servTableWidth >= 1900 ? "adserviceshead" : "d-none"
                  }
                >
                  Max
                </th>
                <th
                  className={
                    servTableWidth >= 800 ? "adserviceshead" : "d-none"
                  }
                >
                  {servTableWidth <= 880
                    ? "Description & Price For 1000"
                    : "Description"}
                </th>
                <th
                  className={
                    servTableWidth >= 880 ? "adserviceshead" : "d-none"
                  }
                >
                  Price For 1000
                </th>
                <th
                  className={
                    servTableWidth >= 1900
                      ? "adserviceshead adservicestbmaxwidth"
                      : "d-none"
                  }
                >
                  Created At
                </th>
              </tr>
            ) : (
              <tr>
                <th className="adserviceshead">
                  <Checkbox
                    onChange={() => handleSelectAll()}
                    checked={selectedServices.length === mainServices.length}
                  />
                </th>
                <th className="adserviceshead">
                  <span className="adservicesselalltxt">
                    Selected <span>{selectedServices.length}</span> Services
                  </span>
                </th>
                <th className="adserviceshead adusactx">
                  <button
                    className="adusersactions"
                    onClick={showAllActionsFunc}
                    ref={allActionsBtnRef}
                  >
                    Actions
                    <FaAngleDown className="adusacIcon" />
                  </button>
                  {showAllActions ? (
                    <div
                      className="adallservicesactions"
                      ref={allActionMenuRef}
                    >
                      <div className="adallserviceactioncon">
                        <ul>
                          <li>
                            <button
                              className="adserviceactionbtn"
                              onClick={copyIDs}
                            >
                              <IoCopy className="adserviceactionbtnicon" />
                              Copy IDs
                            </button>
                          </li>
                          <li>
                            {status === "active" ? (
                              <button
                                className="adserviceactionbtn"
                                onClick={disableServicesConfirm}
                              >
                                <FaEyeSlash className="adserviceactionbtnicon" />
                                Disable All
                              </button>
                            ) : (
                              <button
                                className="adserviceactionbtn"
                                onClick={activateServicesConfirm}
                              >
                                <FaEye className="adserviceactionbtnicon" />
                                Activate All
                              </button>
                            )}
                          </li>
                          <li>
                            <button
                              className="adserviceactionbtn"
                              onClick={handleChangeCategory}
                            >
                              <FaExchangeAlt className="adserviceactionbtnicon" />
                              Change Category
                            </button>
                          </li>
                          <li>
                            <button
                              className="adserviceactionbtn adservicedeletebtn"
                              onClick={deleteServicesConfirm}
                            >
                              <MdDelete className="adserviceactionbtnicon" />
                              Delete All
                            </button>
                          </li>
                        </ul>
                      </div>
                      {changeAllCategory && (
                        <div
                          className="adservicesactionoverlaycon"
                          onClick={(event) => hideActionOverlay(event)}
                          ref={actionsRef}
                        >
                          <div className="adservicesactionoverlay">
                            <strong className="adservoverlayhdtxt">
                              Change Category
                            </strong>
                            <Select
                              className="categoryOptions"
                              menuPortalTarget={document.body}
                              options={categories}
                              value={categoryOption}
                              onChange={handleAllCategoryChange}
                              styles={selectStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  primary: "var(--adbasebgcolor)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </th>
              </tr>
            )}
          </thead>
          {SearchValue === null ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="allServices">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {mainServices.map((service, index) => (
                      <Draggable
                        key={service.uid}
                        draggableId={service.uid}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            className="adservrow"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td className="adservicesbody adservmax20">
                              <Checkbox
                                onChange={() => handleServiceSelect(service.id)}
                                checked={selectedServices.includes(service.id)}
                              />
                            </td>
                            <td
                              className="adservicesbody adservmax50"
                              onClick={() => copyToClipboard(service.id)}
                            >
                              <span className="adorderservID">
                                {service.id}
                              </span>
                            </td>
                            <td className="adservicesbody adservicescatminwitdth">
                              {servTableWidth <= 1330 ? (
                                <div className="adservnameacat">
                                  <strong
                                    className="adservicestbbdcat"
                                    onClick={() =>
                                      showServiceCategory(service.category)
                                    }
                                  >
                                    {service.category}
                                  </strong>
                                  <strong
                                    className="adservicesname"
                                    onClick={() =>
                                      showServiceName(service.name)
                                    }
                                  >
                                    {service.name}
                                  </strong>
                                </div>
                              ) : (
                                <strong
                                  className="adservicestbbdcat"
                                  onClick={() =>
                                    showServiceCategory(service.category)
                                  }
                                >
                                  {service.category}
                                </strong>
                              )}
                            </td>
                            <td
                              className={
                                servTableWidth <= 1330
                                  ? "d-none"
                                  : "adservicesbody adservicesminwitdth"
                              }
                            >
                              <strong
                                className="adservicesname"
                                onClick={() => showServiceName(service.name)}
                              >
                                {service.name}
                              </strong>
                            </td>
                            <td
                              className={
                                servTableWidth >= 1900
                                  ? "adservicesbody"
                                  : "d-none"
                              }
                            >
                              <Link
                                target="_blank"
                                to={`https://${service.provider}`}
                                className="adservicesproviderurl"
                              >
                                {service.provider}
                              </Link>
                            </td>
                            <td
                              className={
                                servTableWidth >= 1900
                                  ? "adservicesbody"
                                  : "d-none"
                              }
                            >
                              <strong>{service.min}</strong>
                            </td>
                            <td
                              className={
                                servTableWidth >= 1900
                                  ? "adservicesbody"
                                  : "d-none"
                              }
                            >
                              <strong>{service.max}</strong>
                            </td>
                            <td
                              className={
                                servTableWidth >= 800
                                  ? "adservicesbody adusactx"
                                  : "d-none"
                              }
                            >
                              {servTableWidth <= 880 ? (
                                <div className="adservnameacat">
                                  <button
                                    className="adusersactions"
                                    onClick={() =>
                                      showServiceDescription(
                                        parse(
                                          service.description.replace(
                                            /&&n/g,
                                            "\n"
                                          )
                                        )
                                      )
                                    }
                                  >
                                    Description
                                  </button>
                                  <div className="d-flex justify-content-center mt-1 align-items-center w-100">
                                    <strong className="adservicesprice">
                                      {getCurrencySymbol(
                                        selectedAdminRate.label
                                      )}
                                      {convertCurrency(
                                        service.price,
                                        service.providerCurrency,
                                        selectedAdminRate.label,
                                        ratesData
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              ) : (
                                <button
                                  className="adusersactions"
                                  onClick={() =>
                                    showServiceDescription(
                                      parse(
                                        service.description.replace(
                                          /&&n/g,
                                          "\n"
                                        )
                                      )
                                    )
                                  }
                                >
                                  Description
                                </button>
                              )}
                            </td>
                            <td
                              className={
                                servTableWidth >= 880
                                  ? "adservicesbody adservicestbmaxwidth"
                                  : "d-none"
                              }
                            >
                              <strong className="adservicesprice">
                                {getCurrencySymbol(selectedAdminRate.label)}
                                {convertCurrency(
                                  service.price,
                                  service.providerCurrency,
                                  selectedAdminRate.label,
                                  ratesData
                                )}
                              </strong>
                            </td>
                            <td
                              className={
                                servTableWidth >= 1900
                                  ? "adservicesbody adservicestbmaxwidth"
                                  : "d-none"
                              }
                            >
                              <strong>{formatDate(service.timestamp)}</strong>
                            </td>
                            <td className="aduserstb-data adusactx">
                              <button
                                className="adusersactions"
                                onClick={() => showServicesActions(service)}
                                ref={headerRef}
                              >
                                {servTableWidth <= 540 ? (
                                  <MdOutlineAdsClick />
                                ) : (
                                  <React.Fragment>
                                    Actions
                                    <FaAngleDown className="adusacIcon" />
                                  </React.Fragment>
                                )}
                              </button>
                              {isOpen && openedServiceId === service.uid && (
                                <div
                                  className="adservicesactions"
                                  ref={menuRef}
                                >
                                  <div className="adserviceactioncon">
                                    <button
                                      className="adserviceactionbtn"
                                      onClick={() => showEditService(service)}
                                    >
                                      <MdEdit className="adserviceactionbtnicon" />
                                      Edit Service
                                    </button>
                                    <button
                                      className="adserviceactionbtn"
                                      onClick={showChangeCategory}
                                    >
                                      <FaExchangeAlt className="adserviceactionbtnicon" />
                                      Change Category
                                    </button>
                                    <button
                                      className="adserviceactionbtn"
                                      onClick={duplicateService}
                                    >
                                      <IoDuplicate className="adserviceactionbtnicon" />
                                      Duplicate
                                    </button>
                                    <button className="adserviceactionbtn asservstatus">
                                      <div className="adservactstatuscon">
                                        {status === "active" ? (
                                          <FaEye className="adserviceactionbtnicon" />
                                        ) : (
                                          <FaEyeSlash className="adserviceactionbtnicon" />
                                        )}
                                        Status
                                        <div className="adservicestatusind">
                                          {status}
                                        </div>
                                      </div>
                                      <Switch
                                        syncValue={serviceStatus}
                                        onToggle={toggleStatus}
                                      />
                                    </button>
                                    <button
                                      className="adserviceactionbtn adservicedeletebtn"
                                      onClick={deleteService}
                                    >
                                      <MdDelete className="adserviceactionbtnicon" />
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              )}
                              {changeCategory && (
                                <div
                                  className="adservicesactionoverlaycon"
                                  onClick={(event) => hideActionOverlay(event)}
                                  ref={actionsRef}
                                >
                                  <div className="adservicesactionoverlay">
                                    <strong className="adservoverlayhdtxt">
                                      Change Category
                                    </strong>
                                    <Select
                                      className="categoryOptions"
                                      menuPortalTarget={document.body}
                                      options={categories}
                                      value={categoryOption}
                                      onChange={handleCategoryChange}
                                      styles={selectStyles}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          primary: "var(--adbasebgcolor)",
                                        },
                                      })}
                                    />
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <tbody>
              {sortedServices.map((service) => (
                <React.Fragment key={service.uid}>
                  <tr>
                    <td className="adservicesbody adservmax20">
                      <Checkbox
                        onChange={() => handleServiceSelect(service.id)}
                        checked={selectedServices.includes(service.id)}
                      />
                    </td>
                    <td
                      className="adservicesbody adservmax50"
                      onClick={() => copyToClipboard(service.id)}
                    >
                      <span className="adorderservID">{service.id}</span>
                    </td>
                    <td className="adservicesbody adservicescatminwitdth">
                      {servTableWidth <= 1330 ? (
                        <div className="adservnameacat">
                          <strong
                            className="adservicestbbdcat"
                            onClick={() =>
                              showServiceCategory(service.category)
                            }
                          >
                            {service.category}
                          </strong>
                          <strong
                            className="adservicesname"
                            onClick={() => showServiceName(service.name)}
                          >
                            {service.name}
                          </strong>
                        </div>
                      ) : (
                        <strong
                          className="adservicestbbdcat"
                          onClick={() => showServiceCategory(service.category)}
                        >
                          {service.category}
                        </strong>
                      )}
                    </td>
                    <td
                      className={
                        servTableWidth <= 1330
                          ? "d-none"
                          : "adservicesbody adservicesminwitdth"
                      }
                    >
                      <strong
                        className="adservicesname"
                        onClick={() => showServiceName(service.name)}
                      >
                        {service.name}
                      </strong>
                    </td>
                    <td
                      className={
                        servTableWidth >= 1900 ? "adservicesbody" : "d-none"
                      }
                    >
                      <Link
                        target="_blank"
                        to={`https://${service.provider}`}
                        className="adservicesproviderurl"
                      >
                        {service.provider}
                      </Link>
                    </td>
                    <td
                      className={
                        servTableWidth >= 1900 ? "adservicesbody" : "d-none"
                      }
                    >
                      <strong>{service.min}</strong>
                    </td>
                    <td
                      className={
                        servTableWidth >= 1900 ? "adservicesbody" : "d-none"
                      }
                    >
                      <strong>{service.max}</strong>
                    </td>
                    <td
                      className={
                        servTableWidth >= 800
                          ? "adservicesbody adusactx"
                          : "d-none"
                      }
                    >
                      {servTableWidth <= 880 ? (
                        <div className="adservnameacat">
                          <button
                            className="adusersactions"
                            onClick={() =>
                              showServiceDescription(
                                parse(service.description.replace(/&&n/g, "\n"))
                              )
                            }
                          >
                            Description
                          </button>
                          <div className="d-flex justify-content-center mt-1 align-items-center w-100">
                            <strong className="adservicesprice">
                              {getCurrencySymbol(selectedAdminRate.label)}
                              {convertCurrency(
                                service.price,
                                service.providerCurrency,
                                selectedAdminRate.label,
                                ratesData
                              )}
                            </strong>
                          </div>
                        </div>
                      ) : (
                        <button
                          className="adusersactions"
                          onClick={() =>
                            showServiceDescription(
                              parse(service.description.replace(/&&n/g, "\n"))
                            )
                          }
                        >
                          Description
                        </button>
                      )}
                    </td>
                    <td
                      className={
                        servTableWidth >= 880
                          ? "adservicesbody adservicestbmaxwidth"
                          : "d-none"
                      }
                    >
                      <strong className="adservicesprice">
                        {getCurrencySymbol(selectedAdminRate.label)}
                        {convertCurrency(
                          service.price,
                          service.providerCurrency,
                          selectedAdminRate.label,
                          ratesData
                        )}
                      </strong>
                    </td>
                    <td
                      className={
                        servTableWidth >= 1900
                          ? "adservicesbody adservicestbmaxwidth"
                          : "d-none"
                      }
                    >
                      <strong>{formatDate(service.timestamp)}</strong>
                    </td>
                    <td className="aduserstb-data adusactx">
                      <button
                        className="adusersactions"
                        onClick={() => showServicesActions(service)}
                        ref={headerRef}
                      >
                        {servTableWidth <= 540 ? (
                          <MdOutlineAdsClick />
                        ) : (
                          <React.Fragment>
                            Actions
                            <FaAngleDown className="adusacIcon" />
                          </React.Fragment>
                        )}
                      </button>
                      {isOpen && openedServiceId === service.uid && (
                        <div className="adservicesactions" ref={menuRef}>
                          <div className="adserviceactioncon">
                            <button
                              className="adserviceactionbtn"
                              onClick={() => showEditService(service)}
                            >
                              <MdEdit className="adserviceactionbtnicon" />
                              Edit Service
                            </button>
                            <button
                              className="adserviceactionbtn"
                              onClick={showChangeCategory}
                            >
                              <FaExchangeAlt className="adserviceactionbtnicon" />
                              Change Category
                            </button>
                            <button
                              className="adserviceactionbtn"
                              onClick={duplicateService}
                            >
                              <IoDuplicate className="adserviceactionbtnicon" />
                              Duplicate
                            </button>
                            <button className="adserviceactionbtn asservstatus">
                              <div className="adservactstatuscon">
                                {status === "active" ? (
                                  <FaEye className="adserviceactionbtnicon" />
                                ) : (
                                  <FaEyeSlash className="adserviceactionbtnicon" />
                                )}
                                Status
                                <div className="adservicestatusind">
                                  {status}
                                </div>
                              </div>
                              <Switch
                                syncValue={serviceStatus}
                                onToggle={toggleStatus}
                              />
                            </button>
                            <button
                              className="adserviceactionbtn adservicedeletebtn"
                              onClick={deleteService}
                            >
                              <MdDelete className="adserviceactionbtnicon" />
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                      {changeCategory && (
                        <div
                          className="adservicesactionoverlaycon"
                          onClick={(event) => hideActionOverlay(event)}
                          ref={actionsRef}
                        >
                          <div className="adservicesactionoverlay">
                            <strong className="adservoverlayhdtxt">
                              Change Category
                            </strong>
                            <Select
                              className="categoryOptions"
                              menuPortalTarget={document.body}
                              options={categories}
                              value={categoryOption}
                              onChange={handleCategoryChange}
                              styles={selectStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  primary: "var(--adbasebgcolor)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {showDetailsOverlay ? (
        <DetailsOverlay
          head={detailsHead}
          body={detaildBody}
          setShowDetailsOverlay={setShowDetailsOverlay}
          actionsRef={actionsRef}
        />
      ) : (
        ""
      )}
      <EditService
        selectedService={selectedEditingService}
        setSelectedService={setSelectedEditingService}
        actionRef={actionsRef}
        fetchServices={fetchServices}
        setFetchServices={setFetchServices}
      />
      {showConfirmAction && (
        <ConfirmAction
          buttonName={buttonName}
          headText={headText}
          actionText={actionText}
          hideConfirmAction={hideConfirmAction}
          cancelConfirmAction={cancelConfirmAction}
          buttonAction={handleSelectedServices}
          actionRef={actionsRef}
          updatingAction={updatingAction}
        />
      )}
      <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
    </div>
  );
}

export default ServicesTable;

import ServicesTable from "../components/ServicesTable";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import LogHeader from "../shared/LogHeader";
import LogNavLinks from "../shared/LogNavLinks";
import Integrations from "../shared/Integrations";
import Header from "../shared/Header";
import { AppContext } from "../../context/AppContext";
import FetchingData from "../shared/FetchingData";
import TopNav from "../components/TopNav";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import Loading from "../shared/Loading";
import ValidTag from "../../utils/ValidTag";

function Services() {
  const [allServices, setAllServices] = useState([]);
  const [mainServices, setMainServices] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const {
    siteTitle,
    loading,
    setLoading,
    siteUrl,
    panelId,
    currentUser,
    siteData,
    darkMode,
    myStyles,
  } = useContext(AppContext);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchService, setShowSearchService] = useState(false);
  const containerRef = useRef();
  const bodyRef = useRef();
  const tableRef = useRef();
  const actionsRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    let handler = (e) => {
      if (
        !actionsRef.current?.contains(e.target) &&
        !bodyRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target) &&
        !containerRef.current?.contains(e.target)
      ) {
        setShowSearchService(false);
        setSearchValue("");
      }
    };
    if (showSearchService) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = `Services | ${siteTitle}`;
    const fetchAllServices = async () => {
      try {
        const response = await axios.post(`${siteUrl}/service/get`, {
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const filteredServices = response.data;
          setAllServices(filteredServices);
          setMainServices(filteredServices);
          setFetchingData(false);
        }
      } catch (error) {
        setFetchingData(false);
      }
    };

    fetchAllServices();
    setLoading(false);
  }, [siteTitle, siteUrl, setLoading, panelId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const SearchValue = searchParams.get("search");
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchValue(parsedValue);
        const dupServices = [...mainServices];
        setAllServices(
          dupServices.filter((service) => {
            if (typeof parsedValue === "string") {
              return service.name
                .toLowerCase()
                .includes(parsedValue.toLowerCase());
            } else if (typeof parsedValue === "number") {
              return String(service.id).includes(String(parsedValue));
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setShowSearchService(true);
    }
  }, [location.search, mainServices]);

  if (loading || siteData === null || fetchingData) {
    return <Loading />;
  }

  const handleSearchInput = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    setSearchValue(parsedValue);
    if (parsedValue !== "") {
      searchParams.set("search", parsedValue);
      const dupServices = [...mainServices];
      setAllServices(
        dupServices.filter((service) => {
          if (typeof parsedValue === "string") {
            return service.name
              .toLowerCase()
              .includes(parsedValue.toLowerCase());
          } else if (typeof parsedValue === "number") {
            return String(service.id).includes(String(parsedValue));
          }
          return false;
        })
      );
    } else {
      setAllServices(mainServices);
      searchParams.delete("search");
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const showSearchBar = () => {
    setShowSearchService(true);
  };

  const handleCancelSearchOrder = () => {
    setShowSearchService(false);
    setSearchValue("");
    searchParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
    setAllServices(mainServices);
  };
  return (
    <React.Fragment>
      {currentUser ? <LogNavLinks /> : isSmallScreen ? <Header /> : ""}
      <LogHeader />
      <Integrations />
      <div
        className={darkMode ? "dark-services-container" : "services-container"}
        style={myStyles}
      >
        <TopNav />
        {fetchingData ? (
          <div className="clservicesmaxwifet">
            <FetchingData />
          </div>
        ) : (
          <div className="services-table">
            <div
              className={
                darkMode ? "darkordersearchcon mb-20" : "ordersearchcon mb-20"
              }
              ref={containerRef}
            >
              {showSearchService ? (
                <div className="searchorderDiv" ref={bodyRef}>
                  <input
                    type="text"
                    name="ordersearch"
                    id={darkMode ? "darkordersearch" : "ordersearch"}
                    className={darkMode ? "text-white" : "text-dark"}
                    value={searchValue}
                    onChange={handleSearchInput}
                    placeholder="Search by ID or Name"
                  />
                  <MdCancel
                    className="adorcancelsearch"
                    onClick={handleCancelSearchOrder}
                  />
                </div>
              ) : (
                <button
                  className={
                    darkMode ? "darkorderssearchbtn" : "orderssearchbtn"
                  }
                  onClick={showSearchBar}
                >
                  <FaSearch className="orsearIcon" />
                  <p>Search Service</p>
                </button>
              )}
            </div>
            <ServicesTable
              services={allServices}
              actionsRef={actionsRef}
              tableRef={actionsRef}
            />
            <ValidTag />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Services;

import "../styles/orderstable.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../../context/AppContext.js";
import Pagination from "../shared/Pagination";
import NotFound from "../shared/NotFound";
import { formatDate } from "../shared/FormatDate";
import Checkbox from "../shared/Checkbox";
import { FaAngleDown, FaSpinner } from "react-icons/fa";
import EditOrder from "./EditOrder.jsx";
import { FaCheckCircle } from "react-icons/fa";
import { MdPending } from "react-icons/md";
import { MdSmsFailed } from "react-icons/md";
import { FcCancel } from "react-icons/fc";
import EditService from "./EditService.jsx";
import { IoCopy } from "react-icons/io5";
import { getCurrencySymbol } from "../shared/CurrencySymbol.jsx";
import { convertCurrency } from "../shared/ConvertCurrency.jsx";

function OrdersTable({ orders, tableRef, actionsRef, setFetchOrders }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const {
    ratesData,
    selectedAdminRate,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    currentAdmin,
  } = useContext(AppContext);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [windowWidth, setWindowWidth] = useState(1200);
  const [providerIDs, setProviderIDs] = useState([]);
  const itemsPerPage = 25;
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef();
  const menuRef = useRef();

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let handler = (e) => {
      if (
        !menuRef.current?.contains(e.target) &&
        !headerRef.current?.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const handleResize = () => {
      const ordersTableCon = document.querySelector(".adorderstablecon");
      if (ordersTableCon) {
        setWindowWidth(ordersTableCon.offsetWidth);
      }
    };

    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  const pageCount = Math.ceil(orders.length / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const sortedOrderDetails = orders.slice(startIndex, endIndex);
  const { status } = useParams();

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1; // Adjust page number (ReactPaginate uses 0-based index)
    setCurrentPage(selected);
    // Update the URL with the new page number
    navigate(`/control-panel/orders/${status}?page=${newPage}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1); // Adjust for 0-based index
  }, []);

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        Notify("success", "Successfully copied ID");
      } else {
        Notify("error", "Failed to copy ID");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  const handleOrderSelect = (orderId) => {
    const isSelected = selectedOrders.includes(orderId.id);
    if (isSelected) {
      setSelectedOrders((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== orderId.id)
      );
      setProviderIDs((prevSelected) =>
        prevSelected.filter(
          (selectedId) => selectedId !== orderId.providerOrderId
        )
      );
    } else {
      setSelectedOrders((prevSelected) => [...prevSelected, orderId.id]);
      setProviderIDs((prevSelected) => [
        ...prevSelected,
        orderId.providerOrderId,
      ]);
    }
  };

  const handleSelectAll = () => {
    const allOrdersIds = orders.map((order) => order.id);
    const allProviderOrdersIds = orders.map((order) => order.providerOrderId);
    setSelectedOrders((prevSelected) =>
      prevSelected.length === orders.length ? [] : allOrdersIds
    );
    setProviderIDs((prevSelected) =>
      prevSelected.length === orders.length ? [] : allProviderOrdersIds
    );
  };

  const showOrderActions = (order) => {
    setSelectedOrder(order);
  };

  const showSelectedService = (order) => {
    if (order && order.service) {
      setSelectedService(order);
    }
  };

  const copyIDs = async () => {
    function convertArrayToString(arr) {
      return arr.join(",");
    }
    let numbersString = convertArrayToString(selectedOrders);
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(numbersString);
        Notify("success", "Successfully copied IDs");
      } else {
        Notify("error", "Failed to copy IDs");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  const copyProviderIDs = async () => {
    function convertArrayToString(arr) {
      return arr.join(",");
    }
    let numbersString = convertArrayToString(providerIDs);
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(numbersString);
        Notify("success", "Successfully copied IDs");
      } else {
        Notify("error", "Failed to copy IDs");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  const calculateProgress = (order) => {
    const remainingAmount = order.number - order.remains;
    return (remainingAmount / order.number) * 100;
  };

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="adorderstablecon">
      {sortedOrderDetails.length === 0 ? (
        <NotFound value={"No matching orders found with the given URL or ID"} />
      ) : (
        <React.Fragment>
          {windowWidth > 1200 ? (
            <div className="adorderstb">
              <table className="adorderstable" ref={tableRef}>
                <thead>
                  {selectedOrders.length === 0 ? (
                    <tr>
                      <th className="adorderstablehead">
                        <Checkbox
                          onChange={() => handleSelectAll()}
                          checked={selectedOrders.length === orders.length}
                        />
                      </th>
                      <th className="adorderstablehead">ID</th>
                      <th className="adorderstablehead">User</th>
                      <th className="adorderstablehead">Price</th>
                      <th className="adorderstablehead">Link</th>
                      <th className="adorderstablehead">Start</th>
                      <th className="adorderstablehead">Service</th>
                      <th className="adorderstablehead">Status</th>
                      <th className="adorderstablehead">Created At</th>
                      {/* Add more columns as needed */}
                    </tr>
                  ) : (
                    <tr>
                      <th className="aduserstb-head">
                        <Checkbox
                          onChange={() => handleSelectAll()}
                          checked={selectedOrders.length === orders.length}
                        />
                      </th>
                      <th className="adorderstablehead adusersthd">
                        <div className="adordersselectedtext">
                          Selected {selectedOrders.length} Orders
                        </div>
                      </th>
                      <th className="adorderstablehead adusersthd">
                        <button
                          className="adusersactions"
                          onClick={toggleOpen}
                          ref={headerRef}
                        >
                          Actions
                          <FaAngleDown className="adusacIcon" />
                        </button>
                        {isOpen && (
                          <div className="adortballsel" ref={menuRef}>
                            <ul className="adulsel">
                              <li className="adliuul">
                                <button className="adbtnsel" onClick={copyIDs}>
                                  <IoCopy className="aduselicon" />
                                  Copy IDs
                                </button>
                              </li>
                              <li className="adliuul">
                                <button
                                  className="adbtnsel"
                                  onClick={copyProviderIDs}
                                >
                                  <IoCopy className="aduselicon" />
                                  Copy Provider IDs
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {sortedOrderDetails.map((order) => (
                    <React.Fragment key={order.id}>
                      <tr>
                        <td className="adorderstabledata">
                          <Checkbox
                            onChange={() => handleOrderSelect(order)}
                            checked={selectedOrders.includes(order.id)}
                          />
                        </td>
                        <td
                          className="adorderstabledata"
                          onClick={() => copyToClipboard(order.id)}
                        >
                          <span className="adorderservID">{order.id}</span>
                        </td>
                        <td className="adorderstabledata">
                          <Link
                            to={`/control-panel/users/all?search=${order.userId}`}
                            className="adordersusername"
                          >
                            <strong>{order.username}</strong>
                          </Link>
                        </td>
                        <td className="adorderstabledata">
                          <strong>
                            {getCurrencySymbol(selectedAdminRate.label)}
                            {convertCurrency(
                              order.price,
                              "USD",
                              selectedAdminRate.label,
                              ratesData
                            )}
                          </strong>
                        </td>
                        <td className="adorderstabledata adorderstdurl">
                          <Link
                            to={order.url}
                            target="_blank"
                            className="adordersurl"
                          >
                            <strong>{order.url}</strong>
                          </Link>
                        </td>
                        <td className="adorderstabledata">
                          <strong>{order.start ? order.start : 0}</strong>
                        </td>
                        <td className="adorderstabledata adorderstbserviceda">
                          <strong
                            className="adortbsvdata"
                            onClick={() => showSelectedService(order)}
                          >
                            <span className="adorderservID">
                              {order.serviceID}
                            </span>
                            {order.service}
                          </strong>
                        </td>
                        <td className="adorderstabledata fw-semibold adordersstatus">
                          {order.status === "In progress" ? (
                            <>
                              <div className="d-flex justify-content-between">
                                <span>
                                  {order
                                    ? order.number - order.remains || 0
                                    : 0}{" "}
                                  of {order.number}
                                </span>
                                <span className="orderstablegreenstat">
                                  {order.status}
                                </span>
                              </div>
                              <div className="clprogresscon">
                                <div
                                  className="greenprogress"
                                  style={{
                                    width: `${calculateProgress(order)}%`,
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : order.status === "Partial" ? (
                            <>
                              <div className="d-flex justify-content-between">
                                <span>
                                  {order
                                    ? order.number - order.remains || 0
                                    : 0}{" "}
                                  of {order.number}
                                </span>
                                <span className="orderstableredstat">
                                  {order.status}
                                </span>
                              </div>
                              <div className="clprogresscon">
                                <div
                                  className="redprogress"
                                  style={{
                                    width: `${calculateProgress(order)}%`,
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : order.status === "Pending" ? (
                            <div className="adordetbbstatmain">
                              <span className="orderstableyellowstat">
                                <MdPending className="orderstablestatsicon" />
                                Pending
                              </span>
                              <div className="adorderstabledescstatcon">
                                <div className="adorderstabledescstat">
                                  You already have an active order on this link
                                </div>
                              </div>
                            </div>
                          ) : order.status === "Completed" ? (
                            <div className="d-flex align-content-center justify-content-between">
                              <strong>
                                {order ? order.number - order.remains || 0 : 0}{" "}
                                of {order.number}
                              </strong>
                              <span className="orderstablegreenstat">
                                <FaCheckCircle className="orderstablestatsicon" />
                                {order.status}
                              </span>
                            </div>
                          ) : order.status === "Failed" ? (
                            <div className="d-flex align-content-center justify-content-between">
                              <strong>
                                {order ? order.number - order.remains || 0 : 0}{" "}
                                of {order.number}
                              </strong>
                              <div className="adordetbbstatmain">
                                <span className="orderstableredstat">
                                  <MdSmsFailed className="orderstablestatsicon" />
                                  {order.status}
                                </span>
                                <div className="adorderstabledescstatcon">
                                  <div className="adorderstabledescstat">
                                    {order.providerError}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : order.status === "Canceled" ? (
                            <div className="d-flex align-content-center justify-content-between">
                              <strong>
                                {order ? order.number - order.remains || 0 : 0}{" "}
                                of {order.number}
                              </strong>
                              <span className="orderstableredstat">
                                <FcCancel className="orderstablestatsicon" />
                                {order.status}
                              </span>
                            </div>
                          ) : order.status === "Processing" ? (
                            <div className="d-flex align-content-center justify-content-between">
                              <strong>0 of {order.number}</strong>
                              <span className="orderstablebluestat">
                                <FaSpinner className="orderstablestatsicon" />
                                {order.status}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="adorderstabledata orders-date">
                          <strong>{formatDate(order.timestamp)}</strong>
                        </td>
                        <td className="aduserstb-data">
                          <button className="adusersactions">View</button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="admborderscon" ref={tableRef}>
              <div className="admbordersnav">
                {selectedOrders.length === 0 ? (
                  <React.Fragment>
                    <Checkbox
                      onChange={() => handleSelectAll()}
                      checked={selectedOrders.length === orders.length}
                    />
                    <div className="adordersselectedtext">
                      Selected 0 Orders
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Checkbox
                      onChange={() => handleSelectAll()}
                      checked={selectedOrders.length === orders.length}
                    />
                    <div className="adordersselectedtext">
                      Selected {selectedOrders.length} Orders
                    </div>
                    <button
                      className="adusersactions"
                      onClick={toggleOpen}
                      ref={headerRef}
                    >
                      Actions
                      <FaAngleDown className="adusacIcon" />
                    </button>
                    {isOpen && (
                      <div className="adortballsel" ref={menuRef}>
                        <ul className="adulsel">
                          <li className="adliuul">
                            <button className="adbtnsel" onClick={copyIDs}>
                              <IoCopy className="aduselicon" />
                              Copy IDs
                            </button>
                          </li>
                          <li className="adliuul">
                            <button
                              className="adbtnsel"
                              onClick={copyProviderIDs}
                            >
                              <IoCopy className="aduselicon" />
                              Copy Provider IDs
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
              {sortedOrderDetails.map((order) => (
                <div
                  className="admborderdet"
                  key={order.id}
                  onClick={() => showOrderActions(order)}
                >
                  <div className="admborderdethead">
                    <div
                      className="d-flex gap-20"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        onChange={(e) => handleOrderSelect(order)}
                        checked={selectedOrders.includes(order.id)}
                      />
                      <span
                        className="adorderservID"
                        onClick={(e) => copyToClipboard(order.id)}
                      >
                        {order.id}
                      </span>
                    </div>
                    <Link
                      to={order.url}
                      target="_blank"
                      className="admborderurl"
                    >
                      <strong>{order.url}</strong>
                    </Link>
                    <strong>{order.number}</strong>
                  </div>
                  <div className="admborderdetbody">
                    <strong
                      className="admbservice"
                      onClick={(e) => {
                        showSelectedService(order);
                        handleStopPropagation(e);
                      }}
                    >
                      <span className="adorderservID">{order.serviceID}</span>
                      {order.service}
                    </strong>
                    <strong>
                      {getCurrencySymbol(selectedAdminRate.label)}
                      {convertCurrency(
                        order.price,
                        "USD",
                        selectedAdminRate.label,
                        ratesData
                      )}
                    </strong>
                  </div>
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      )}
      <EditOrder
        selectedOrder={selectedOrder}
        adminApiKey={currentAdmin.apiKey}
        actionsRef={actionsRef}
        setSelectedOrder={setSelectedOrder}
        setFetchOrders={setFetchOrders}
      />
      <EditService
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        actionRef={actionsRef}
      />
      <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
    </div>
  );
}

export default OrdersTable;

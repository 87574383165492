import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { getData } from "../../utils/indexedDB";
import React, { useContext, useEffect, useState } from "react";
import "../styles/support.css";
import SupportTable from "../components/SupportTable";
import axios from "axios";
import Integrations from "../shared/Integrations";
import LogNavLinks from "../shared/LogNavLinks";
import LogHeader from "../shared/LogHeader";
import getNextId from "../shared/IdIncrement";
import ValidTag from "../../utils/ValidTag";
import TopNav from "../components/TopNav";
import TipTapEditor from "../shared/TipTapEditor";
import FetchingData from "../shared/FetchingData";
import NotFound from "../components/NotFound";
import Loading from "../shared/Loading";

function Support() {
  const {
    myStyles,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    darkMode,
    siteUrl,
    siteTitle,
    loading,
    setLoading,
    panelId,
    currentUser,
    siteData,
  } = useContext(AppContext);
  const options = [
    { value: "order", label: "Order" },
    { value: "payment", label: "Payment" },
    { value: "api", label: "API" },
    { value: "service", label: "Service" },
    { value: "bugReport", label: "Bug Report" },
    { value: "request", label: "Request" },
    { value: "childPanel", label: "Child Panel" },
    { value: "other", label: "Other" },
  ];

  const orderOptions = [
    { value: "refill", label: "Refill" },
    { value: "cancellation", label: "Cancellation" },
    { value: "notStarted", label: "Not Started" },
    { value: "speedUp", label: "Speed Up" },
    { value: "notCompleted", label: "Mark As Completed Without Done" },
    { value: "restart", label: "Restart" },
    { value: "other", label: "Other" },
  ];

  const childPanelOptions = [
    { value: "iNeedChildPanel", label: "I Need Child Panel" },
    { value: "renewMyChildPanel", label: "Renew My Child Panel" },
  ];

  const bugReportOptions = [
    { value: "bugInWebsite", label: "Bug In Website" },
    { value: "bugInServices", label: "Bug In Services" },
  ];

  const requestOptions = [
    { value: "addServices", label: "Add Service" },
    { value: "addFeature", label: "Add Feature" },
    { value: "addPayment", label: "Add Payment" },
  ];

  const [mainSubject, setMainSubject] = useState(null);
  const [orderSubsubject, setOrderSubsubject] = useState(null);
  const [paymentSubsubject, setPaymentSubsubject] = useState(null);
  const [childPanelSubsubject, setChildPanelSubsubject] = useState(null);
  const [bugReportSubsubject, setBugReportSubsubject] = useState(null);
  const [requestSubsubject, setRequestSubsubject] = useState(null);
  const [idCounter, setIdCounter] = useState(1);
  const [message, setMessage] = useState("");
  const [orderId, setOrderId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [emalId, setEmailId] = useState("");
  const [amont, setAmont] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [discountServiceId, setDiscountServiceId] = useState("");
  const [ticketsData, setTicketsData] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [changedSupportDB, setChangedSupportDB] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  const handleDiscountServiceIdChange = (e) => {
    setDiscountServiceId(e.target.value);
  };

  const handleWhatsappChange = (e) => {
    setWhatsapp(e.target.value);
  };

  const handleWebUrlChange = (e) => {
    setWebUrl(e.target.value);
    setIsButtonDisabled(false);
  };

  const handleAmontChange = (e) => {
    setAmont(e.target.value);
  };

  const handleEmailIdChange = (e) => {
    setEmailId(e.target.value);
  };

  const handleTransactionIdChange = (e) => {
    setTransactionId(e.target.value);
    setIsButtonDisabled(false);
  };
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value);
    setIsButtonDisabled(false);
  };

  const handleTextareaChange = (e) => {
    setMessage(e);
    setIsButtonDisabled(false);
  };

  const handleMainSubjectChange = (selectedOption) => {
    setMainSubject(selectedOption);
    setMessage("");
    setIsButtonDisabled(false);
  };

  const handleOrderSubsubjectChange = (selectedOption) => {
    setOrderSubsubject(selectedOption);
  };

  const handlePaymentSubsubjectChange = (selectedOption) => {
    setPaymentSubsubject(selectedOption);
  };

  const handleChildPanelSubsubjectChange = (selectedOption) => {
    setChildPanelSubsubject(selectedOption);
  };

  const handleBugReportSubsubjectChange = (selectedOption) => {
    setBugReportSubsubject(selectedOption);
  };

  const handleRequestSubsubjectChange = (selectedOption) => {
    setRequestSubsubject(selectedOption);
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Support | ${siteTitle}`;
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading, siteTitle]);

  useEffect(() => {
    const fetchAllTickets = async () => {
      try {
        if (currentUser) {
          const response = await axios.post(
            `${siteUrl}/support/user-get-tickets`,
            {
              panelId: panelId,
              key: currentUser.apiKey,
              uid: currentUser.uid,
            }
          );
          setTicketsData(response.data);
        }
        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
      }
    };

    fetchAllTickets();
    const IdIncrement = async () => {
      try {
        const newID = await getNextId("supports", panelId, siteUrl);
        setIdCounter(newID);
      } catch (error) {
        setIdCounter(1);
      }
    };
    IdIncrement();
  }, [siteUrl, currentUser, panelId, changedSupportDB]);

  useEffect(() => {
    const fetchPaymentGateways = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const url = `${siteUrl}/payment/gateways/get`;
          const data = {
            uid: currentUser.uid,
            panelId: panelId,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setGateways(response.data);
        } catch (error) {}
      }
    };
    fetchPaymentGateways();
  }, [siteUrl, currentUser, panelId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isButtonDisabled) return;

    setIsButtonDisabled(true); // Disable the button

    const fields = {
      orderSubsubject,
      orderId,
      transactionId,
      emalId,
      amont,
      paymentSubsubject,
      whatsapp,
      webUrl,
      message,
      bugReportSubsubject,
      requestSubsubject,
      childPanelSubsubject,
    };

    const subjectConfig = {
      order: {
        requiredFields: ["orderSubsubject", "orderId"],
        data: {
          orderId: fields.orderId,
          request: fields.orderSubsubject?.label,
        },
      },
      payment: {
        requiredFields: [
          "transactionId",
          "emalId",
          "amont",
          "paymentSubsubject",
        ],
        data: {
          transactionId: parseInt(fields.transactionId, 10),
          email: fields.emalId,
          amount: parseFloat(fields.amont),
          request: fields.paymentSubsubject?.label,
        },
      },
      api: {
        requiredFields: ["whatsapp", "webUrl"],
        data: {
          websiteUrl: fields.webUrl,
          discountServiceId: parseInt(discountServiceId, 10),
          whatsapp: parseInt(fields.whatsapp, 10),
        },
      },
      service: {
        requiredFields: ["message"],
        data: {
          message: fields.message,
        },
      },
      bugReport: {
        requiredFields: ["message", "bugReportSubsubject"],
        data: {
          request: fields.bugReportSubsubject?.label,
          message: fields.message,
        },
      },
      request: {
        requiredFields: ["message", "requestSubsubject"],
        data: {
          request: fields.requestSubsubject?.label,
          message: fields.message,
        },
      },
      childPanel: {
        requiredFields: ["message", "childPanelSubsubject"],
        data: {
          request: fields.childPanelSubsubject?.label,
          message: fields.message,
        },
      },
      other: {
        requiredFields: ["message"],
        data: {
          message: fields.message,
        },
      },
    };

    try {
      if (!mainSubject) {
        throw new Error("Please select a subject");
      }

      const subjectData = subjectConfig[mainSubject.value];
      if (!subjectData) {
        throw new Error("Invalid subject selected");
      }

      // Validate required fields
      subjectData.requiredFields.forEach((field) => {
        const value = fields[field];
        if (!value || (typeof value === "string" && value.trim() === "")) {
          throw new Error("Please ensure that you fill in all fields.");
        }
      });

      const requestData = {
        panelId: panelId,
        key: currentUser.apiKey,
        data: {
          userId: currentUser.uid,
          id: idCounter,
          status: "Pending",
          subject: mainSubject.label,
          timestamp: new Date(),
          ...subjectData.data,
        },
      };

      await axios.post(`${siteUrl}/support/create-ticket`, requestData);

      setIsButtonDisabled(false);
      // Reset fields
      setMainSubject(null);
      setAmont("");
      setOrderId("");
      setBugReportSubsubject(null);
      setChildPanelSubsubject(null);
      setOrderSubsubject(null);
      setPaymentSubsubject(null);
      setEmailId("");
      setDiscountServiceId("");
      setTransactionId("");
      setWebUrl("");
      setWhatsapp("");
      setChangedSupportDB(!changedSupportDB);
      Notify("success", `Ticket ${idCounter} submitted successfully!`);
    } catch (error) {
      Notify("error", error.message);
      setIsButtonDisabled(false);
    }
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "20px", // Add border-radius to the control (main container)
      borderColor: "grey",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--stbaseactcolor)"
          : darkMode
          ? "black"
          : "white", // Change background color on hover
        color: isFocused ? "white" : darkMode ? "white" : "black",
        fontSize: "var(--selectfs)",
      };
    },
  };

  if (loading || siteData === null || fetchingData) {
    return <Loading />;
  }

  const customNoOptionsMessage = ({ inputValue, selectType }) => {
    return inputValue
      ? `No matching ${selectType} found`
      : `No ${selectType} available`;
  };

  return (
    <React.Fragment>
      <LogHeader />
      <Integrations />
      <LogNavLinks />
      <div className={darkMode ? "dark-support" : "support"} style={myStyles}>
        <TopNav />
        <div className="support-container">
          <div className={darkMode ? "dark-support-form" : "support-form"}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="options">Subject</label>
              <Select
                options={options}
                isClearable
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    primary: "var(--stbaseactcolor)",
                  },
                })}
                noOptionsMessage={(props) =>
                  customNoOptionsMessage({ ...props, selectType: "subject" })
                }
                placeholder="Select subject"
                styles={selectStyles}
                value={mainSubject}
                onChange={handleMainSubjectChange}
              />
              {mainSubject && mainSubject.value === "order" && (
                <div className="ord-form mt-1">
                  <label htmlFor="orderId">Order ID</label>
                  <input
                    type="text"
                    name="orderId"
                    id={darkMode ? "dark-orderId" : "orderId"}
                    onChange={handleOrderIdChange}
                    value={orderId}
                    placeholder="For multiple orders, please separate them using comma. (example: 12345,12345,12345)"
                  />
                  <label htmlFor="orderSelect">Request</label>
                  <Select
                    options={orderOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary: "var(--stbaseactcolor)",
                      },
                    })}
                    noOptionsMessage={(props) =>
                      customNoOptionsMessage({
                        ...props,
                        selectType: "request",
                      })
                    }
                    placeholder="Select request"
                    styles={selectStyles}
                    value={orderSubsubject}
                    onChange={handleOrderSubsubjectChange}
                  />
                  <div className="sup-msg mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              {mainSubject && mainSubject.value === "payment" && (
                <div className="payment-form mt-1 ">
                  <label htmlFor="transactionId">Transaction ID</label>
                  <input
                    type="number"
                    name="transactionId"
                    id={darkMode ? "dark-transactionId" : "transactionId"}
                    onChange={handleTransactionIdChange}
                    placeholder="Enter the transaction ID"
                    value={transactionId}
                  />
                  <label htmlFor="emailId">Email</label>
                  <input
                    type="email"
                    name="emailId"
                    id={darkMode ? "dark-emailId" : "emailId"}
                    value={emalId}
                    placeholder="Enter your email"
                    onChange={handleEmailIdChange}
                  />
                  <label htmlFor="amont">Amount</label>
                  <input
                    type="number"
                    name="amont"
                    id={darkMode ? "dark-amont" : "amont"}
                    value={amont}
                    placeholder="Enter the amount"
                    onChange={handleAmontChange}
                  />
                  <label htmlFor="orderSelect">Payment Method</label>
                  <Select
                    options={gateways}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary: "var(--stbaseactcolor)",
                      },
                    })}
                    noOptionsMessage={(props) =>
                      customNoOptionsMessage({
                        ...props,
                        selectType: "payment method",
                      })
                    }
                    placeholder="Select payment method"
                    styles={selectStyles}
                    value={paymentSubsubject}
                    onChange={handlePaymentSubsubjectChange}
                  />
                  <div className="sup-msg  mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              {mainSubject && mainSubject.value === "api" && (
                <div className="api-form mt-1">
                  <label htmlFor="websiteUrl">Website URL</label>
                  <input
                    type="url"
                    name="websiteUrl"
                    id={darkMode ? "dark-websiteUrl" : "websiteUrl"}
                    placeholder="Enter your website URL"
                    value={webUrl}
                    onChange={handleWebUrlChange}
                  />
                  <label htmlFor="whatsapp">Whatsapp</label>
                  <input
                    type="number"
                    name="whatsapp"
                    id={darkMode ? "dark-whatsapp" : "whatsapp"}
                    value={whatsapp}
                    placeholder="Enter your whatsapp/skype number we can use in reaching you"
                    onChange={handleWhatsappChange}
                  />
                  <label htmlFor="discountServiceId">Discount Service Id</label>
                  <input
                    type="number"
                    name="discountServiceId"
                    id={
                      darkMode ? "dark-discountServiceId" : "discountServiceId"
                    }
                    value={discountServiceId}
                    placeholder="Enter the service ID you want discout on"
                    onChange={handleDiscountServiceIdChange}
                  />
                  <div className="sup-msg mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              {mainSubject && mainSubject.value === "service" && (
                <div>
                  <div className="sup-msg mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              {mainSubject && mainSubject.value === "childPanel" && (
                <div className="mt-1">
                  <label htmlFor="cpSelect">Child Panel Request</label>
                  <Select
                    options={childPanelOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary: "var(--stbaseactcolor)",
                      },
                    })}
                    noOptionsMessage={(props) =>
                      customNoOptionsMessage({
                        ...props,
                        selectType: "request",
                      })
                    }
                    placeholder="Select request"
                    styles={selectStyles}
                    value={childPanelSubsubject}
                    onChange={handleChildPanelSubsubjectChange}
                  />
                  <div className="sup-msg mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              {mainSubject && mainSubject.value === "bugReport" && (
                <div className="mt-1">
                  <label htmlFor="brSelect">Bug Request</label>
                  <Select
                    options={bugReportOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary: "var(--stbaseactcolor)",
                      },
                    })}
                    noOptionsMessage={(props) =>
                      customNoOptionsMessage({
                        ...props,
                        selectType: "bug request",
                      })
                    }
                    placeholder="Select bug request"
                    styles={selectStyles}
                    value={bugReportSubsubject}
                    onChange={handleBugReportSubsubjectChange}
                  />
                  <div className="sup-msg mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              {mainSubject && mainSubject.value === "request" && (
                <div className="mt-1">
                  <label htmlFor="requestSelect">Add Request</label>
                  <Select
                    options={requestOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary: "var(--stbaseactcolor)",
                      },
                    })}
                    noOptionsMessage={(props) =>
                      customNoOptionsMessage({
                        ...props,
                        selectType: "request",
                      })
                    }
                    placeholder="Select request"
                    styles={selectStyles}
                    value={requestSubsubject}
                    onChange={handleRequestSubsubjectChange}
                  />
                  <div className="sup-msg mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              {mainSubject && mainSubject.value === "other" && (
                <div>
                  <div className="sup-msg mt-2">
                    <label htmlFor="message">Message</label>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleTextareaChange}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                </div>
              )}
              <div className="subBtn">
                <button
                  type="submit"
                  className="subMessage"
                  disabled={isButtonDisabled}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
          {fetchingData ? (
            <div className="clmaxwifseromob">
              <FetchingData />
            </div>
          ) : (
            <>
              {ticketsData.length === 0 ? (
                <NotFound
                  value={"You didn't create any tickets yet"}
                  linkName={"Create a ticket"}
                  dfStyle={"notfoundforsupportstyle"}
                />
              ) : (
                <SupportTable ticketsData={ticketsData} />
              )}
            </>
          )}
        </div>
        <ValidTag />
      </div>
    </React.Fragment>
  );
}

export default Support;

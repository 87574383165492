import { formatDate } from "../shared/FormatDate";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import React, { useContext, useState, useEffect } from "react";
import { FcCancel } from "react-icons/fc";
import { Link } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../context/AppContext.js";
import { CiEdit } from "react-icons/ci";
import "../styles/editorders.css";
import DetailsOverlay from "../shared/DetailsOverlay";
import { IoSendSharp } from "react-icons/io5";
import { VscDebugStart } from "react-icons/vsc";
import relaxemoji from "../../assets/images/263A_color.png";
import { getCurrencySymbol } from "../../client/shared/CurrencySymbol.jsx";
import { convertCurrency } from "../../client/shared/ConvertCurrency.jsx";
import Switch from "../shared/Switch.jsx";

function EditOrder({
  actionsRef,
  selectedOrder,
  setSelectedOrder,
  setFetchOrders,
  adminApiKey,
}) {
  const {
    siteUrl,
    panelId,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    ratesData,
    currentAdmin,
    selectedAdminRate,
  } = useContext(AppContext);
  const [partialAmount, setPartialAmount] = useState(0);
  const [detailsBody, setDetailsBody] = useState("");
  const [detailsHead, setDetailsHead] = useState("");
  const [link, setLink] = useState("");
  const [showDetailsOverlay, setShowDetailsOverlay] = useState(false);
  const [startCount, setStartCount] = useState(0);
  const [syncOrder, setSyncOrder] = useState(true);
  const [order, setOrder] = useState({});

  useEffect(() => {
    const getSelectedOrder = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            key: currentAdmin.apiKey,
            panelId: panelId,
            query: {
              find: { field: "id", operator: "===", value: selectedOrder?.id },
            },
          });
          const data = response.data;
          setOrder(data);
          setSyncOrder(data.syncOrder);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getSelectedOrder();
  }, [siteUrl, panelId, currentAdmin, selectedOrder]);

  const hideOrderActions = (event) => {
    // Check if the click occurred outside the content
    if (event.target.classList.contains("ortboverlay")) {
      setSelectedOrder(null);
      setPartialAmount(0);
      setLink("");
      setStartCount(0);
    }
  };

  const toggleSyncOrder = async () => {
    const newValue = !syncOrder;
    await axios.post(`${siteUrl}/crud/update/doc`, {
      collection: "orders",
      key: currentAdmin.apiKey,
      panelId: panelId,
      uid: order.uid,
      data: { syncOrder: newValue },
    });
    setSyncOrder(newValue);
  };

  const showDetails = (body, head) => {
    setShowDetailsOverlay(true);
    setDetailsHead(head);
    setDetailsBody(body);
  };

  const cancelOrderActions = () => {
    setSelectedOrder(null);
    setPartialAmount(0);
    setLink("");
    setStartCount(0);
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        Notify("success", "Successfully copied ID");
      } else {
        Notify("error", "Failed to copy ID");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  const handlePartialInput = (e) => {
    const value = e.target.value;
    setPartialAmount(value);
  };

  const handleLinkInput = (e) => {
    const value = e.target.value;
    setLink(value);
  };

  const handleStartCountInput = (e) => {
    const value = e.target.value;
    setStartCount(value);
  };

  const enteredOrderAmount =
    selectedOrder?.number - selectedOrder?.remains || 0;

  const markOrderAsCompleted = async () => {
    try {
      const url = `${siteUrl}/order/edit`;
      const data = {
        panelId: panelId,
        key: adminApiKey,
        action: "completeOrder",
        status: selectedOrder.status,
        uid: selectedOrder.uid,
        serviceId: selectedOrder.serviceID,
        quantity: selectedOrder.number,
        remains: selectedOrder.remains,
        userUid: selectedOrder.userUid,
      };
      const response = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
      });
      Notify("success", response.data.message);
      setFetchOrders((prev) => !prev);
      setSelectedOrder(null);
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  const resendToProvider = async () => {
    try {
      const url = `${siteUrl}/order/resend`;
      const data = {
        orderData: selectedOrder,
        panelId: panelId,
      };
      await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
      });
      Notify("success", "Sent successfully");
      setFetchOrders((prev) => !prev);
      setSelectedOrder(null);
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  const cancelAndRefund = async () => {
    try {
      const url = `${siteUrl}/order/edit`;
      const data = {
        panelId: panelId,
        key: adminApiKey,
        action: "cancelOrder",
        uid: selectedOrder.uid,
        userUid: selectedOrder.userUid,
      };
      const response = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
      });
      Notify("success", response.data.message);
      setFetchOrders((prev) => !prev);
      setSelectedOrder(null);
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  const editOrderLink = async (e) => {
    e.preventDefault();
    try {
      const url = `${siteUrl}/order/edit`;
      const data = {
        panelId: panelId,
        key: adminApiKey,
        action: "editOrderLink",
        uid: selectedOrder.uid,
        link: link,
      };
      const response = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
      });
      Notify("success", response.data.message);
      setFetchOrders((prev) => !prev);
      setSelectedOrder(null);
      setLink("");
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  const setstartcount = async (e) => {
    e.preventDefault();
    try {
      const url = `${siteUrl}/order/edit`;
      const data = {
        panelId: panelId,
        key: adminApiKey,
        action: "setStartCount",
        uid: selectedOrder.uid,
        startCount: startCount,
      };
      const response = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
      });
      Notify("success", response.data.message);
      setFetchOrders((prev) => !prev);
      setSelectedOrder(null);
      setStartCount(0);
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  const showPartialOrderCon = () => {
    const actionCon = document.querySelector(".partialcon");
    const actions = document.querySelector(".ortbhide");
    actions.classList.add("hide");
    actionCon.classList.remove("hide");
  };

  const showStartCountCon = () => {
    const actionCon = document.querySelector(".startcountcon");
    const actions = document.querySelector(".ortbhide");
    actions.classList.add("hide");
    actionCon.classList.remove("hide");
  };

  const showEditLInkCon = () => {
    const actionCon = document.querySelector(".editlinkcon");
    const actions = document.querySelector(".ortbhide");
    actions.classList.add("hide");
    actionCon.classList.remove("hide");
  };

  const cancelPartially = async (e) => {
    e.preventDefault();
    try {
      const url = `${siteUrl}/order/edit`;
      const data = {
        panelId: panelId,
        key: adminApiKey,
        amount: partialAmount,
        serviceId: selectedOrder.serviceID,
        action: "cancelOrderPartially",
        uid: selectedOrder.uid,
        userUid: selectedOrder.userUid,
      };
      const response = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
      });
      Notify("success", response.data.message);
      setSelectedOrder(null);
      setFetchOrders((prev) => !prev);
      setPartialAmount(0);
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  return (
    <React.Fragment>
      {selectedOrder && order && (
        <div
          className="ortboverlay"
          onClick={(event) => hideOrderActions(event)}
          ref={actionsRef}
        >
          <div className="ortbcfn">
            <div className="ortbhide">
              <div className="ortbsseldet">
                <div className="ortbservdet">
                  <strong className="ortbservdet">{order.service}</strong>
                </div>
                <div className="ortbdet">
                  <table className="ordettable">
                    <tbody>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>ID</strong>
                        </td>
                        <td className="ortbdetdata">
                          <div onClick={() => copyToClipboard(order.id)}>
                            <span className="adorderservID">{order.id}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>Link</strong>
                        </td>
                        <td className="ortbdetdata ortbdeturl">
                          <strong>
                            <Link to={order.url}>{order.url}</Link>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>Price</strong>
                        </td>
                        <td className="ortbdetdata">
                          <div className="fw-semibold ">
                            {getCurrencySymbol(selectedAdminRate.label)}
                            {convertCurrency(
                              order.price,
                              "USD",
                              selectedAdminRate.label,
                              ratesData
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>User Initial Balance</strong>
                        </td>
                        <td className="ortbdetdata">
                          <div className="fw-semibold ">
                            {getCurrencySymbol(selectedAdminRate.label)}
                            {convertCurrency(
                              order.userInitialBalance,
                              "USD",
                              selectedAdminRate.label,
                              ratesData
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>User Final Balance</strong>
                        </td>
                        <td className="ortbdetdata">
                          <div className="fw-semibold">
                            {getCurrencySymbol(selectedAdminRate.label)}
                            {convertCurrency(
                              order.userFinalBalance,
                              "USD",
                              selectedAdminRate.label,
                              ratesData
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>Created At</strong>
                        </td>
                        <td className="ortbdetdata">
                          <div className="fw-semibold ">
                            {formatDate(order.timestamp)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>Status</strong>
                        </td>
                        <td className="ortbdetdata">
                          <strong>
                            {enteredOrderAmount}{" "}
                            <span className="ortbedof">Of</span> {order.number}
                          </strong>
                          <div className="fw-semibold ortbedstatus">
                            {order.status}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>Provider</strong>
                        </td>
                        <td className="ortbdetdata ortbdeturl">
                          <strong>
                            <Link to={`https://${order.provider}`}>
                              {order.provider}
                            </Link>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>Provider ID</strong>
                        </td>
                        <td className="ortbdetdata">
                          <div
                            onClick={() =>
                              copyToClipboard(order.providerOrderId)
                            }
                          >
                            <span className="adorderservID">
                              {order.providerOrderId}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ortbdetdata">
                          <strong>Provider Price</strong>
                        </td>
                        <td className="ortbdetdata">
                          <strong>
                            {getCurrencySymbol(selectedAdminRate.label)}
                            {convertCurrency(
                              order.providerPrice,
                              "USD",
                              selectedAdminRate.label,
                              ratesData
                            )}
                          </strong>
                        </td>
                      </tr>
                      {order.comments ? (
                        <tr>
                          <td className="ortbdetdata">
                            <strong>Comments</strong>
                          </td>
                          <td className="ortbdetdata ortbcomments">
                            <strong
                              onClick={() =>
                                showDetails(order.comments, "Comments")
                              }
                              className="ortbcomments"
                            >
                              View Comments
                            </strong>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td className="ortbdetdata">
                          <strong>User</strong>
                        </td>
                        <td className="ortbdetdata ortbdeturl">
                          <strong>
                            <Link
                              to={`/control-panel/users/all?search=${order.userId}`}
                            >
                              {order.username}
                            </Link>
                          </strong>
                        </td>
                      </tr>
                      {order.status === "Failed" && (
                        <tr>
                          <td className="ortbdetdata">
                            <strong>Error Message</strong>
                          </td>
                          <td className="ortbdetdata">
                            <strong className="ortbedderrsg">
                              {order.providerError}
                            </strong>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="ortbactionsbtn">
                {order.provider !== "manual" && (
                  <div className="adsyncorder">
                    <strong>Sync order with provider</strong>
                    <Switch syncValue={syncOrder} onToggle={toggleSyncOrder} />
                  </div>
                )}
                {order.status === "Failed" && order.provider !== "manual" ? (
                  <button className="ortbbtns" onClick={resendToProvider}>
                    <IoSendSharp className="ortbbtnsicon" /> Resend To Provider
                  </button>
                ) : (
                  ""
                )}
                {order.provider !== "manual" &&
                order.status !== "Failed" &&
                syncOrder ? (
                  <div className="adsyncedorderprovider">
                    <img src={relaxemoji} alt="" className="adsyncordprvimg" />
                    <span className="adsyncordprovtxt">
                      Orders from providers will be synced automatically.
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {order.status !== "Completed" && !syncOrder ? (
                  <button className="ortbbtns" onClick={markOrderAsCompleted}>
                    <FaRegCheckCircle className="ortbbtnsicon" />
                    Mark As Completed
                  </button>
                ) : (
                  ""
                )}
                {order.status !== "Canceled" &&
                order.status !== "Partial" &&
                !syncOrder ? (
                  <button className="ortbcancelorder" onClick={cancelAndRefund}>
                    <FcCancel className="ortbbtnsicon" />
                    Cancel and Refund
                  </button>
                ) : (
                  ""
                )}
                {order.status !== "Partial" &&
                order.status !== "Canceled" &&
                !syncOrder ? (
                  <button
                    className="ortbcancelorder"
                    onClick={showPartialOrderCon}
                  >
                    <MdOutlineCancel className="ortbbtnsicon" />
                    Cancel Partially
                  </button>
                ) : (
                  ""
                )}
                {!syncOrder ? (
                  <button className="ortbbtns" onClick={showEditLInkCon}>
                    <CiEdit className="ortbbtnsicon" />
                    Edit Order Link
                  </button>
                ) : (
                  ""
                )}
                {!syncOrder ? (
                  <button className="ortbbtns" onClick={showStartCountCon}>
                    <VscDebugStart className="ortbbtnsicon" />
                    Set Start Count
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="partialcon hide">
              <div className="orachead">Cancel Partially</div>
              <form onSubmit={cancelPartially}>
                <label htmlFor="amount">
                  <strong>Remains</strong>
                </label>
                <input
                  type="number"
                  name="amount"
                  id="partialAmountInput"
                  value={partialAmount}
                  onChange={handlePartialInput}
                />
                <button type="submit" className="ortbbtnupdate">
                  Save
                </button>
              </form>
            </div>
            <div className="editlinkcon hide">
              <div className="orachead">Edit Link</div>
              <form onSubmit={editOrderLink}>
                <label htmlFor="amount">
                  <strong>Link</strong>
                </label>
                <input
                  type="url"
                  name="link"
                  id="editLinkInput"
                  value={link}
                  onChange={handleLinkInput}
                />
                <button type="submit" className="ortbbtnupdate">
                  Save
                </button>
              </form>
            </div>
            <div className="startcountcon hide">
              <div className="orachead">Set Start Count</div>
              <form onSubmit={setstartcount}>
                <label htmlFor="amount">
                  <strong>Start Count</strong>
                </label>
                <input
                  type="number"
                  name="number"
                  id="startCountInput"
                  value={startCount}
                  onChange={handleStartCountInput}
                />
                <button type="submit" className="ortbbtnupdate">
                  Save
                </button>
              </form>
            </div>
            <button className="ortbselcancel" onClick={cancelOrderActions}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {showDetailsOverlay && (
        <DetailsOverlay
          head={detailsHead}
          body={detailsBody}
          setShowDetailsOverlay={setShowDetailsOverlay}
          actionsRef={actionsRef}
        />
      )}
    </React.Fragment>
  );
}

export default EditOrder;
